<template>
  <!-- 单档劵 -->
  <div class="core-info__sol-mall">
    <h2
      ref="title"
      class="core-info__title"
    >
      <span class="price">
        {{ discount }}
      </span>
      <!-- 是否赠品，Membership -->
      <span
        :class="['boost', { visible: data.isBoosted }]"
      >
        <img
          v-if="boostIconSrc"
          :src="boostIconSrc"
          alt="boost-icon"
          :style="{
            transform: `scaleX(${GB_cssRight ? -1 : 1})`
          }"
        />
        {{ language.SHEIN_KEY_PWA_36149 }}
      </span>
      <span
        v-if="rules[0]?.discountExtra"
        class="gift"
      >
        {{ rules[0]?.discountExtra }}
      </span>
    </h2>
    <!-- 相关信息（单行、多行） -->
    <!-- 单档单行用class-few，单档多行用class-more -->
    <div
      ref="textContainer"
      :class="[
        'core-info__other',
        isTextLong ? 'core-info__more' : 'core-info__few'
      ]"
    >
      <!-- 使用门槛 -->
      <div
        v-if="rules[0]?.threshold"
        class="apply-orders"
      >
        <span>{{ rules[0]?.threshold }}</span>
      </div>
      <!-- 分割线 -->
      <span
        v-if="expectedSavedMoney || discountMaxLimit"
        class="core-info__split-line"
      ></span>
      <CouponInfo
        :is-multi="false"
        :data="data"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NotMultiCoupon',
})
</script>
<script setup>
import { ref, onMounted, computed, watch, inject } from 'vue'
import CouponInfo from './CouponInfo'
import { useNumberRolling } from 'public/src/pages/common/hooks/useNumberRolling'
import { boostedTextIcon } from '../../utils.js'
import { EventBus } from '@/public/src/pages/common/event-bus.js'


const { GB_cssRight } = gbCommonInfo
const language = inject('couponLanguage')
const expandedPrice = inject('expandedPrice')
let isTextLong = ref(false)
let textContainer = ref(null)

//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const expectedSavedMoney = computed(() => props.data?.expectedSavedMoney)
const discountMaxLimit = computed(() => props.data?.discountMaxLimit)
const rules = computed(() => props.data?.rules)

const discount = computed(() => {
  const res = GB_cssRight ? rules?.value?.[0]?.discount?.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1') :  rules?.value?.[0]?.discount || ''

  const num = +rules?.value?.[0]?.discountAmount
  const count = num ? res?.split(num)?.join(currentValue.value) : res
  return count
})

const boostIconSrc = computed(() => {
  return boostedTextIcon[props?.data?.couponColorType]
})

let currentValue = ref(null)

const setExpandAnimationEnd = () => {
  // 动效结束通知业务更新券列表接口
  EventBus.emit('button-click', {
    eventName: 'couponListRefresh',
    couponInfo: props.data,
  })
}

const discountAmount = ref(+props.data.rules[0]?.discountAmount)
if (discountAmount.value) {
  const num = useNumberRolling({
    value: discountAmount,
    onEnd: setExpandAnimationEnd,
    stepDecimalPoint: props.data?.separatorFormat?.decimalPlace,
    duration: 600,
  })
  currentValue.value = num.value
}

const boost = () => {
  discountAmount.value = expandedPrice.value
}

watch(() => props.data.isBoosted, (newVal) => {
  if (newVal) {
    boost() 
  }
})

onMounted(() => {
  heightStyle(20)
})

//判断文本是否超过一行
const heightStyle = num => {
  let height = textContainer.value?.clientHeight || 0
  if (height > num) {
    isTextLong.value = true
  } else {
    isTextLong.value = false
  }
}
</script>

<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.core-info {
  &__title {
    display: inline-flex;
    align-items: baseline;
    gap: 0.053rem 0.1067rem;
    align-self: stretch;
    flex-wrap: wrap;
    max-width: 100%;
    color: var(--core-color);
    word-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 700;
    font-size: 0.6933rem;
    line-height: 0.6933rem;
    margin-bottom: 0.1067rem;
    .gift {
      font-size: 0.42667rem;
      text-transform: none;
      display: flex;
      align-items: center;
    }
    .boost {
      font-size: 0.42667rem;
      text-transform: none;
      display: flex;
      align-items: center;
      opacity: 0;
      width: 0;
      display: none;
      transition: all 0.6s ease-in-out;
    }
    .boost.visible {
      opacity: 1;
      width: auto;
      display: flex;
    }
  }
  &__other {
    max-width: 6.1333rem;
    .apply-orders,
    .apply-Capped,
    .apply-Save {
      max-width: 100%;
      .base-font(0.32rem;);
      line-height: normal;
      word-wrap: break-word;
      span {
        color: var(--secondary-color);
        // font-weight: 300;
      }
    }
    .core-info__split-line {
      width: 0.0267rem;
      height: 0.24rem;
      opacity: 0.3;
      background: var(--secondary-color);
    }
  }
  &__split-line {
    opacity: 0.3;
    background: var(--secondary-color);
    width: 1px;
    height: 0.23rem;
  }
  &__few {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.09rem;
  }
  &__more {
    .info-title {
      display: block;
    }
    .core-info__split-line {
      display: none;
    }
  }
  .showy {
    color: var(--core-color) !important;
    font-weight: 700;
  }
}

.boost-icon {
  width: 0.3733rem;
  height: 0.3733rem;
}
</style>
