<template>
  <s-drawer
    v-model:visible="isShowCouponGoodsDrawer"
    v-expose="reportData"
    :append-to-body="true"
  >
    <template #title>
      {{ title }}
    </template>
    <div 
      v-if="isForBff && subTitle?.length"
      class="coupon-goods__title"
    >
      <li
        v-for="(item, index) in subTitle"
        :key="index"
        v-html="xssEncode(item)"
      ></li>
    </div>
    <div 
      v-else-if="!isForBff"
      class="coupon-goods__title"
      v-html="xssEncode(subTitle, xssOptions)"
    >
    </div>
    <div class="coupon-goods__list">
      <div
        v-for="(item, index) in list"
        :key="index" 
        class="coupon-goods__item square-size" 
      >
        <i
          v-if="item?.isChecked"
          class="suiiconfont sui_icon_select_selected_16px selected"
        ></i>
        <div class="item-img__box">
          <img
            class="lazyload item-img"
            :src="LAZY_IMG"
            :data-src="transformImg({ img: item.goodsImg })"
          />
          <div class="item-img__mask"></div>
        </div>
        <p
          class="item-price"
          :class="{ 'discount-price': item.hasDiffPrice || false }"
        >
          {{ item.goodsPrice }}
        </p>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import xssEncode from 'xss'

daEventCenter.addSubscriber({ modulecode: '2-38' })
const { IS_RW, LAZY_IMG } = gbCommonInfo

export default defineComponent({
  name: 'CouponGoodsList',
  components: {
    SDrawer,
  },
  directives: {
    expose,
  },
  inject: ['couponDataInfo', 'couponConfig', 'isForBff'],
  emits: ['update:isShow'],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: [String, Array],
      default: () => [],
    },
    list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      IS_RW,
      LAZY_IMG: LAZY_IMG,
      // 自定义xss过滤规则
      xssOptions: {
        whiteList: {
          a: ['href', 'title', 'target'],
          img: ['src', 'alt'],
          p: ['class', 'style'],
          span: ['class', 'style'],
          li: ['class', 'style'],
          div: ['class', 'style'],
        }
      }
    }
  },
  computed: {
    isShowCouponGoodsDrawer: {
      get () {
        return this.isShow || false
      },
      set (val) {
        this.$emit('update:isShow', val)
      }
    },
    reportData () {
      return {
        id: '2-38-5',
        data: {
          activity_from: this.couponConfig?.activityFrom,
          coupon_id: this.couponDataInfo?.id,
          coupon_code: this.couponDataInfo?.coupon,
          if_useful: this.couponDataInfo?.satisfiedRange ? 1 : 0,
          sku_id: this.list.filter(item => item?.goodsSn)?.map(item => item?.goodsSn).join(','),
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    transformImg,
    xssEncode,
  },
})
</script>

<style lang="less">
.coupon-goods__title {
  font-size: 12px;
  line-height: .3733rem;
  padding: .32rem;
  background-color: #F6F6F6;

  li {
    list-style: disc;
  }
}
.coupon-goods__list {
  padding: .32rem .32rem 1.28rem .32rem;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
}
.coupon-goods__item {
  position: relative;
  width: calc((100% - .64rem) / 4);
  .margin-r(.21333rem);
  margin-bottom: .32rem;
  &:nth-child(4n) {
    .margin-r(0);
  }
  .item-img {
    width: 100%;
    height: 2.9333rem;
    margin-bottom: .10667rem;
  }
  .item-price {
    font-weight: 700;
    color: @sui_color_main;
  }
  .discount-price {
    color: @sui_color_discount;
  }

  &.square-size {
    .item-img {
      width: 100%;
      margin-bottom: 0;
      &__box {
        position: relative;
        display: flex;
        align-items: center;
        height: calc((100vw - 1.28rem) / 4);
        margin-bottom: .10667rem;
        overflow: hidden;
      }
      &__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .03);
      }
    }
    .selected {
      position: absolute;
      z-index: 1;
      font-size: 20/75rem !important; /* stylelint-disable-line declaration-no-important */
      height: 20/75rem;
      line-height: 20/75rem;
      right: 4/75rem;
      top: 4/75rem;
      opacity: 0.3;
    }
  }
}
</style>

<style lang="less" scoped>
.coupon-goods__item-price {
  line-height: 1.2;
  :deep(.price-amount) {
    font-size: 12/75rem;
  }
}
</style>
