<template>
  <div
    v-if="!noneInfo"
    class="coupon-info"
  >
    <!-- <div class="coupon-info"> -->
    <!-- 新到提示,special_tips为1时，展示券面新到标签 -->
    <div
      v-if="isNew"
      class="new-tag"
    >
      {{ language?.SHEIN_KEY_PWA_30961 }}
      <!-- 分割点，需要根据类型判断，如果有主体或者类型，显示分割点 -->
    </div>
    <span
      v-if="isNew && (cIcon || iconPay || couponInfoType)"
      class="dot"
    ></span>
    <!-- 主体 -->
    <div
      v-if="cIcon"
      :class="[cIcon && 'icon-max-w', 'coupon-info__main']"
    >
      <img
        :src="cIcon"
        alt="Tag Icon"
        class="icon"
      />
    </div>
    <!-- 分割点，需要根据类型判断，如果没有主体或类型则不显示 -->
    <span
      v-if="cIcon && (couponInfoType || iconPay)"
      class="dot"
    ></span>
    <!-- 支付类型 -->
    <div
      v-if="iconPay"
      :class="['icon-max-w', 'coupon-info__iconPay']"
    >
      <span class="coupon-info__main_text">
        {{ language?.SHEIN_KEY_PWA_30958 }}
      </span>
      <img
        :src="iconPay"
        alt="Tag Icon"
        class="pay_img"
      />
    </div>
    <span
      v-if="iconPay && couponInfoType"
      class="dot"
    ></span>
    <!-- 券类型 -->
    <span
      v-if="couponInfoType"
      class="coupon-info__type"
    >
      {{ couponInfoType }}
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CouponInfo',
})
</script>
<script setup>
import { defineEmits, inject, computed, watch } from 'vue'
import { getImgUrl, businessType } from '../../utils'

let data = inject('couponDataInfo')
let language = inject('couponLanguage')
//解构数据
let {
  couponSheinType = '',
  couponColorType = '',
  couponBusinessType = '',
  isNew,
  iconPay
} = data || {}

const emit = defineEmits(['update-none-info'])

//获取主体图片地址
const cIcon = computed(() => getImgUrl(couponSheinType, couponColorType)) || ''
//获取券类型
const couponInfoType = computed(() => businessType(couponBusinessType, language.value)) || ''
//判断是否tag全没有
const noneInfo = computed(() => !cIcon.value && !couponInfoType?.value && !iconPay && !isNew)

watch(noneInfo, (val) => {
  // 向父组件发送事件，并传递 noneInfo 的值
  emit('update-none-info', val)
}, { immediate: true })
</script>

<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.coupon-info {
  background-color: var(--left-top-tag-color);
  padding: 0 0.1067rem 0 0.1333rem;
  border-radius: 3.5px 0 0.1067rem 0;
  max-width: calc(100% - 1.4rem);
  color: var(--secondary-color);
  float: left;
  height: 0.453rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .icon-max-w {
    max-width: initial;
  }
  &__main {
    // line-height: 0.2667rem;
    margin-top: -0.0533rem;
    &_text {
      .base-font(0.2667rem);
      margin-right: 0.06rem;
    }
    .icon {
      max-width: 1.8667rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__iconPay {
    &_text {
      .base-font(0.2667rem);
      line-height: 0.453rem;
      margin-right: 0.06rem;
    }
  }
  &__type {
    .base-font(0.2667rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 0.453rem;
  }
  .dot {
    border-radius: 32px;
    background: var(--secondary-color);
    opacity: 0.3;
    width: 3px;
    height: 3px;
    display: inline-block;
    margin: 0 0.1067rem;
  }
}
.new-tag {
  color: #3cbd45;
  text-transform: capitalize;
  .base-font(0.2667rem);
  font-weight: 800;
}
.pay_img {
  height: 0.4rem;
}
</style>
