import { ref, watch, onUnmounted, unref } from 'vue'

// 一帧的时长
const TIME_PER_FRAME = 16.7

export function useNumberRolling({
  value,
  onEnd,
  stepDecimalPoint = 0,
  duration = 300,
}) {
  const frameCount = duration / TIME_PER_FRAME
  const currentValue = ref(unref(value))
  const timer = ref(null)

  watch(
    value,
    (newValue, oldValue) => {
      const diff = newValue - oldValue
      const isAdd = newValue > oldValue
      let step = +(diff / frameCount).toFixed(stepDecimalPoint)

      clearInterval(timer.value)

      if (step === 0 && diff > 1) {
        step = 1
      }
      if (step === 0) {
        currentValue.value = newValue
        onEnd?.()
        return
      }
      timer.value = setInterval(() => {
        currentValue.value = +(currentValue.value + step).toFixed(stepDecimalPoint)

        if (isAdd) {
          if (currentValue.value >= newValue) {
            currentValue.value = newValue
          }
        } else {
          if (currentValue.value <= newValue) {
            currentValue.value = newValue
          }
        }

        if (currentValue.value === newValue) {
          clearInterval(timer.value)
          timer.value = null
          onEnd?.()
        }
      }, TIME_PER_FRAME)
    },
    { flush: 'sync' },
  )

  onUnmounted(() => {
    clearInterval(timer.value)
  })

  return currentValue
}
