<template>
  <div
    id="couponItem"
    ref="coupon"
    class="coupon-item"
    :style="computedStyle"
    da-expose-code="coupon-item-expose"
  >
    <div
      v-expose="reportData"
      da-event-expose="coupon-item-expose"
      class="box"
    >
      <main class="coupon-item__main">
        <!-- 券对象以及类型&新到提示 -->
        <CouponType
          :data="couponData"
          :language="language"
          @update-none-info="handleNoneInfoUpdate"
        />
        <!-- 左上角没有标签时,class变更lackTags -->
        <div
          ref="couponBody"
          :class="[
            hasTags ? 'hasTags' : 'lackTags',
            'coupon-item_body',
            data?.isSupportExpand ? 'expand' : '',
            !hasTags && data?.surpriseBonus ? 'hasSurpriseBonus' : ''
          ]"
        >
          <div class="info">
            <!-- 核心利益点&相关信息 -->
            <CoreInfo
              :data="couponData"
              :language="language"
            />
            <!-- 次级信息 -->
            <OverlayRule
              :data="couponData"
              :language="language"
            />
            <!-- 单档核心规则信息 -->
            <CategoryInfo
              v-if="!isMulti"
              :data="couponData"
              :language="language"
            />
          </div>
          <!-- 交互按钮以及相关的引导性提示 -->
          <HeadRight
            :data="couponData"
            :language="language"
          />
        </div>
        <!-- 多档核心规则信息 -->
        <CategoryInfo
          v-if="isMulti"
          :data="couponData"
          :language="language"
        />
        <!-- 蒙层 -->
        <div
          v-if="needMask"
          class="overlay"
        ></div>
      </main>
      <div
        ref="splitLine"
        class="coupon-item__split-line"
      >
        <div class="coupon-item__split-line_semicircle left"></div>
        <div class="coupon-item__split-line_semicircle right"></div>
        <div class="coupon-item__split-line_dashed"></div>
      </div>
    </div>
    <footer
      ref="footer"
      class="coupon__footer"
    >
      <!-- 优惠券附加信息 -->
      <SupplementaryInfo
        :coupon-info="couponData"
        :language="language"
        :coupon-config="couponConfig"
        :expanded="expanded"
      />
      <!-- 附加组件 -->
      <AdditionalComponents
        :coupon-config="couponConfig"
        :coupon-info="couponData"
        :language="language"
      />
      <!-- 问号指引弹窗 -->
      <template v-if="isForBff">
        <CouponGoodsPop
          v-if="iShowCouponGoodsPopFromHelp"
          v-model:is-show="iShowCouponGoodsPopFromHelp"
          :title="couponData?.businessExtension.order.questionMarkInfo?.popupWindowTitle"
          :sub-title="couponData?.businessExtension.order.questionMarkInfo?.popupWindowTips"
          :list="couponData?.businessExtension.order.questionMarkInfo?.couponAvaliableGoodsList"
        />
      </template>
      <template v-else>
        <CouponGoodsPop
          v-if="iShowCouponGoodsPopFromHelp"
          v-model:is-show="iShowCouponGoodsPopFromHelp"
          :title="couponConfig?.addModule?.content?.popupWindowTitle"
          :sub-title="couponConfig?.addModule?.content?.popupWindowTips"
          :list="couponConfig?.addModule?.content?.couponAvaliableGoodsList"
        />
      </template>
    </footer>
  </div>
</template>

<script setup>
import CoreInfo from './components/CoreInfo'
import CategoryInfo from './components/CategoryInfo.vue'
import CouponType from './components/CouponType'
import HeadRight from './components/HeadRight'
import OverlayRule from './components/OverlayRule.vue'
import SupplementaryInfo from './components/SupplementaryInfo'
import { ref, computed, watch, defineEmits, provide, onMounted, onBeforeUnmount, nextTick, defineAsyncComponent } from 'vue'
import { handleCouponColor, getParentBgColor } from './utils'
import { EventBus } from '@/public/src/pages/common/event-bus.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose as vExpose } from 'public/src/pages/common/analysis/directive.js'
import { CLICK_EVENT_FROM, ACTIVITY_FROM } from 'public/src/pages/common/common-coupon/constants.js'
import CouponGoodsPop from './components/CouponGoodsPop.vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'

import {
  clickCouponComponent,
  clickAddCouponComponent
} from './analysis/index.js'
import schttp from '@/public/src/services/schttp'

daEventCenter.addSubscriber({ modulecode: '2-38' })

const AdditionalComponents = defineAsyncComponent(() => import('./components/AdditionalComponents'))

let hasTags = ref(true)
let coupon = ref(null)
let splitLine = ref(null)
let footer = ref(null)
//颜色
let parentBgColor = ref('#fff')

const props = defineProps({
  // 优惠券数据
  data: {
    type: Object,
    default: () => {}
  },
  //使用方配置类
  couponConfig: {
    type: Object,
    default: () => {}
  },
  //多语言
  language: {
    type: Object,
    default: () => {}
  },
  expanded: {
    type: String,
    default: ''
  },
  isForBff: {
    type: Boolean,
    default: false
  },
})
const infoUpdated = ref(null)
const isBoostBtnLoading = ref(false)
const expandedPrice = ref(props.data?.rules?.[0]?.discountAmount)
const progressExposeData = ref(props.couponConfig?.progressExposeData)

const couponData = computed(() => {
  if(infoUpdated.value) {
    return {
      ...props.data,
      ...infoUpdated.value
    }
  }

  return {
    ...props.data,
  }
})
const language = ref(props.language)
//向孙组件提供数据
provide('couponDataInfo', props?.data)
provide('couponLanguage', language)
provide('couponConfig', props?.couponConfig)
// 券是否有效
provide('isCouponValid', couponData.value?.isValid)
// 是否走 bff 融合
provide('isForBff', props?.isForBff)
// 膨胀按钮是否加载中
provide('isBoostBtnLoading', isBoostBtnLoading)
// 膨胀后价格
provide('expandedPrice', expandedPrice)
// 进度曝光数据
provide('progressExposeData', progressExposeData)

// 预先声明组件要触发的事件
const emits = defineEmits(['button-click'])
//解构数据
// 券面颜色配置
const backgoundConfig = computed(() => handleCouponColor(couponData.value))

//叠加规则，默认不展示
const needMask = computed(() => couponData.value?.needMask)
const isMulti = computed(() => couponData.value?.rules?.length > 1)

//传入的样式
const computedStyle = computed(() => ({
  '--core-color': backgoundConfig.value?.coreColor,
  '--secondary-color': backgoundConfig.value?.secondaryColor,
  '--bg-color': backgoundConfig.value?.bgColor,
  '--border-color': backgoundConfig.value?.borderColor,
  '--btn-color': backgoundConfig.value?.btnColor,
  '--mask-color': backgoundConfig.value?.maskColor,
  '--countdown-bg-color': backgoundConfig.value?.countDownBgColor,
  '--supplementary-info-text-color':
    backgoundConfig.value?.supplementaryInfoTextColor,
  '--parent-bg-color': parentBgColor.value,
  '--left-top-tag-color': backgoundConfig.value?.leftTopTagColor,
  '--label-bg-color': backgoundConfig.value?.labelBgColor,
  '--label-text-color': backgoundConfig.value?.labelTextColor,
  // webkitMask: `radial-gradient(circle, transparent 5px, black 5px) -${
  //   couponWidth.value / 2
  // }px ${Math.ceil(couponHeight.value)}px`
}))

const reportData = computed(() => {
  let infrom = '-'
  if (couponData.value?.promptInfo?.includes('best_choice')) {
    infrom = 1
  } else if (couponData.value?.promptInfo?.includes('stackable')) {
    infrom = 2
  }
  let act_id = couponData.value?.isValid ? props?.couponConfig?.buttonInfo : '0'
  act_id = couponData.value?.isSupportExpand ? '10' : act_id
  return {
    id: '2-38-1',
    data: {
      activity_from: props?.couponConfig?.activityFrom,
      coupon_id: couponData.value?.id,
      coupon_code: couponData.value?.coupon,
      type_id: couponData.value?.typeId,
      act_id: act_id,
      coupon_Inform: infrom,
      coupon_saving: couponData.value?.saveMoney || couponData.value?.expectedSavedMoney,
      coupon_mark: couponData.value?.waterMark,
      category_label: couponData.value?.categoryId?.length || 0,
      if_boost: couponData.value?.isBoosted,
      if_surprise: couponData.value?.surpriseBonus ? 1 : 0,
    }
  }
})

const handleNoneInfoUpdate = value => {
  // 更新父组件的数据
  hasTags.value = !value
}

const  getExpandCouponData = async(params) => {
  const data = await schttp({
    url: '/cart-api/expand/expandCoupon',
    method: 'POST',
    useBffApi: true,
    data: params
  })
  isBoostBtnLoading.value = false

  if (data?.code !== '0' || !data?.info?.bindId ) {
    SToast(language.value.SHEIN_KEY_PWA_35191)
    return
  }

  // 更新膨胀数据
  infoUpdated.value = {
    isSupportExpand: 0,
    isBoosted: 1,
    waterMark: 7,
  }

  expandedPrice.value = data?.info.rules[0].discountAmount
}

const iShowCouponGoodsPopFromHelp = ref(false)

const handleExpandEvent = async () => {
  isBoostBtnLoading.value = true

  clickCouponComponent(couponData.value, {
    ...props.couponConfig,
    buttonInfo: 8,
  })
  
  await getExpandCouponData({
    bindId: couponData.value.bindId?.toString(),
    scene: props?.couponConfig.activityFrom
  })
}

const handleButtonClick = (clickInfo) => {
  emits('button-click', clickInfo)
  // 点击事件埋点上报
  switch (clickInfo.from) {
    case CLICK_EVENT_FROM.couponLayer:
      clickCouponComponent(couponData.value, props.couponConfig)
      break

    case CLICK_EVENT_FROM.addModule:
      clickAddCouponComponent(clickInfo, props.couponConfig)
      break
    default:
      break
  }
  // 点击了问号气泡
  if (clickInfo.from === CLICK_EVENT_FROM.couponHelp) {
    iShowCouponGoodsPopFromHelp.value = true
  }
}

EventBus.on('button-click', clickInfo => {
  if (clickInfo?.couponInfo?.identity !== couponData.value.identity) {
    return
  }
  if (clickInfo.eventName === 'expand') {
    handleExpandEvent()
    return
  }
  if (props?.couponConfig?.activityFrom === ACTIVITY_FROM.order && clickInfo?.couponInfo?.checkoutCouponIndex === props?.data.checkoutCouponIndex) {
    // 下单页场景(下单存在不同 tab 下出现同一张券的情况，需要用 checkoutCouponIndex 区分)
    handleButtonClick(clickInfo)

  } else if (props?.couponConfig?.activityFrom !== ACTIVITY_FROM.order) {
    // 非下单页场景
    handleButtonClick(clickInfo)
  }
})

watch(() => props.language, (newLanguage) => {
  language.value = newLanguage
})

onMounted(async () => {
  // 券面侧边小孔颜色配置
  const parentBackgroundConfig = getParentBgColor('couponItem')
  nextTick(() => {
    parentBgColor.value = parentBackgroundConfig
  })
})

onBeforeUnmount(() => {
  if(props?.couponConfig?.activityFrom === 'goods_Component')EventBus.off('button-click')
})
</script>

<style lang="less" scoped>
// 优惠券容器
.coupon-item {
  position: relative;
  width: calc(100% - 0.7rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;

  .box {
    // mask-image: radial-gradient(circle at 6px 50%, transparent 6px, #fff 6px);
    // mask-position: -6px 1.26rem;
    background-color: var(--bg-color, #ffe2cf);
    border-radius: 4px 4px 0 0;
  }

  &__main {
    position: relative;
    flex: 1;
    padding-bottom: 0.1067rem;
    border-style: solid;
    border-width: 0.5px 0.5px 0 0.5px;
    border-color: var(--border-color, #ffe2cf);
    border-radius: 4px 4px 0 0;
    align-items: center;
    gap: 0.32rem;
  }

  &_body {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.32rem;

    .info {
      flex: 1 0 0;
      max-width: calc(100% - 2rem);
    }
  }
  .hasTags {
    padding: 0.15rem 24/75rem 0 14/75rem;
  }
  .lackTags {
    padding: 0.2667rem 24/75rem 0 14/75rem;
  }
  .hasSurpriseBonus {
    margin-top: 34/75rem;
  }
  .expand {
    padding-right: 0.4rem;
  }

  &__split-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.2667rem;

    &_semicircle {
      position: absolute;
      width: 0.16rem;
      height: 0.2667rem;
      top: 0;
      background: var(--parent-bg-color, #fff);
      border: 0.5px solid var(--border-color);
      &.left {
        left: 0;
        border-radius: 0 0.16rem 0.16rem 0;
        border-left-color: transparent;
      }

      &.right {
        right: 0;
        border-radius: 0.16rem 0 0 0.16rem;
        border-right-color: transparent;
      }
    }

    &_dashed {
      width: calc(95% - 0.2rem);
      border-bottom: 1px dashed var(--border-color);
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--mask-color);
    pointer-events: none;
  }
}
</style>
