<template>
  <div class="coupon-surprise">
    <div class="icon"></div>

    <div class="text">
      {{ language.SHEIN_KEY_PWA_36396 || surpriseBonus }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CouponLabel',
}
</script>
<script setup>
import { inject } from 'vue'

const language = inject('couponLanguage')
let originData = inject('couponDataInfo')

let { surpriseBonus = '' } = originData || {}
</script>

<style lang="less" scoped>
@vw: 37.5rem;

.coupon-surprise {
  display: flex;
  align-items: center;
  font-size: 10/@vw;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: -0.1px;

  .icon {
    display: inline-block;
    background-color: var(--label-bg-color, #fee4de);
    width: 10/@vw;
    height: 17/@vw;
    clip-path: path(
      'M0 0H76C78.2091 0 80 1.79086 80 4V17C80 17 16.9999 17 11 17C5.00009 17 5 0 0 0Z'
    );
    transform: scaleX(1)/*rtl: scaleX(-1)*/;
  }

  .text {
    height: 17/@vw;
    padding: 0 2/@vw 0 0;
    line-height: 17px;
    border-top-right-radius: 2/@vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--label-bg-color, #fee4de);
    color: var(--label-text-color, #f6451e);
  }
}
</style>
