<template>
  <div :class="[noneInfo && 'noneInfo', 'coupon_type']">
    <!-- 劵类型&提示 -->
    <CouponInfo @update-none-info="handleNoneInfoUpdate" />
    <!-- 劵数量 -->
    <CouponCount v-if="!surpriseBonus" />
    <!-- 劵右上角标签 -->
    <CouponLabel v-else />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CouponType',
})
</script>

<script setup>
import { inject } from 'vue'
import CouponInfo from './CouponInfo'
import CouponCount from './CouponCount'
import CouponLabel from './CouponLabel'

import { ref, onMounted, defineEmits } from 'vue'

let originData = inject('couponDataInfo')
//是否全没有
let noneInfo = ref(true)

const emit = defineEmits(['update-none-info'])

//解构数据
let { surpriseBonus = '' } = originData || {}

onMounted(() => {
  // 向父组件发送事件，并传递 noneInfo 的值
  emit('update-none-info', noneInfo.value)
})

const handleNoneInfoUpdate = value => {
  // 更新父组件的数据
  noneInfo.value = value
}
</script>

<style lang="less" scoped>
.coupon_type {
  height: 0.4533rem;
  line-height: 0.43rem;
  display: flex;
  align-items: flex-start;
  gap: 0.3733rem;
  justify-content: space-between;
}

.noneInfo {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
